.foundingMembers {
	&__heading {
		text-align: center;
	}

	&__member {
		position: relative;

		@include breakpoint-large-from () {
			display: flex;
			align-items: flex-start;
		}

		&::before {
			content: "";
			display: block;
			width: multiple-of-lineHeight-unit(6);
			height: multiple-of-lineHeight-unit(6);
			background-position: right top;
			background-repeat: no-repeat;
			background-size: auto 100%;

			@include breakpoint-medium-until () {
				float: right;
				margin-right: -#{multiple-of-lineHeight-unit(1)};
				margin-top: multiple-of-lineHeight-unit(2);
				margin-left: multiple-of-lineHeight-unit(0.5);
				margin-bottom: multiple-of-lineHeight-unit(0.5);
			}

			@supports (shape-outside: url("https://example.com")) {
				@include breakpoint-medium-until () {
					shape-outside: circle(#{multiple-of-lineHeight-unit(3)} at calc(100% - #{multiple-of-lineHeight-unit(3)}) calc(#{multiple-of-lineHeight-unit(5)}));
					shape-margin: multiple-of-lineHeight-unit(0.5);
				}
			}

			@include breakpoint-large-from () {
				flex-shrink: 0;
				flex-basis: 33.3%;
			}
		}
		&::after {
			content: "";
			position: absolute;
			top: 8.5rem;
			display: block;
			width: 3.2rem;
			height: 4rem;
			background-repeat: no-repeat;
			background-position: center;
			background-size: auto 100%;
			z-index: -1;
		}

		&--kubesova {
			margin-bottom: multiple-of-lineHeight-unit(2);

			&::before {
				background-image: url("../images/profiles/kubesova.png");

				@include breakpoint-large-from () {
					background-position-x: 66.6%;
				}
			}
			&::after {
				background-image: url("../images/profiles/kubesova-neurons.svg");

				@include breakpoint-medium-until () {
					right: 5rem;
				}
				@include breakpoint-large-from () {
					top: 7rem;
					left: 12.5rem;
				}
			}
		}

		&--brazdil {
			&::before {
				background-image: url("../images/profiles/brazdil.png");

				@include breakpoint-large-from () {
					order: 1;
					background-position-x: 33.3%;
				}
			}
			&::after {
				background-image: url("../images/profiles/brazdil-neurons.svg");
				right: 6rem;

				@include breakpoint-large-from () {
					top: 6.5rem;
					right: 13.1rem;
				}
			}
		}
	}

	&__innerWrapper {
		padding-top: multiple-of-lineHeight-unit(0.5);
	}
}
