@import "~sanitize.css/sanitize.css";
@import "~sanitize.css/typography.css";
@import "~sanitize.css/forms.css";

// IE on Windows Phone 8.1: http://www.vzhurudolu.cz/prirucka/viewport-windows
@-ms-viewport {
	width: device-width;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

p {
	margin: 0;
}

ol,
ul {
	margin: 0;
	padding: 0;
}
