body {
	color: $colors-text;
	font-family: "Roboto", sans-serif;
	font-size: 109%;
	line-height: $typography-defaultLineHeight;
	max-width: 60rem;
	margin-left: auto;
	margin-right: auto;
	padding-left: multiple-of-lineHeight-unit(0.5);
	padding-right: multiple-of-lineHeight-unit(0.5);
	padding-bottom: 30vh; // let user scroll end of the content to a readable place

	@include breakpoint-medium-until () {
		@include breakpoint-medium-from () {
			max-width: 40rem;
		}
	}

	@include breakpoint-large-from () {
		padding-left: multiple-of-lineHeight-unit(1);
		padding-right: multiple-of-lineHeight-unit(1);
	}
}

a {
	color: $colors-text;
	text-decoration: underline;

	&:visited {
		color: #704B6C;
	}

	&:hover {
		color: darken($colors-text, 30);
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $colors-heading;
	font-family: "Oswald", sans-serif;
}

h1 {
	font-size: 1.5em;
	font-weight: normal;
	line-height: 1.2;
	margin-bottom: multiple-of-lineHeight-unit(0.5);
}

h2 {
	font-size: 1.1em;
	font-weight: normal;
}

p:not(:last-child) {
	margin-bottom: multiple-of-lineHeight-unit(1);
}

img,
svg {
	max-width: 100%;
}

.sr-only {
	// copied from https://www.vzhurudolu.cz
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}
