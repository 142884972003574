.project-InSafety {
	&__description {
		@include breakpoint-large-from () {
			display: flex;
			align-items: flex-start;
		}
	}

	&__text {
		@include breakpoint-medium-until () {
			margin-bottom: multiple-of-lineHeight-unit(1);
		}

		@include breakpoint-large-from () {
			margin-right: multiple-of-lineHeight-unit(2);
		}
	}

	&__infographics {
		@include breakpoint-large-from () {
			flex-basis: 40%;
			flex-shrink: 0;
		}
	}

	&__image {
		margin-bottom: multiple-of-lineHeight-unit(1);
	}

	&__explanatory {
		padding-left: multiple-of-lineHeight-unit(1);
	}
}
