$breakpoint-smallToMedium: 37em;
@mixin breakpoint-small-until () {
	@media (max-width: $breakpoint-smallToMedium) {
		@content;
	}
}
@mixin breakpoint-medium-from () {
	@media (min-width: ($breakpoint-smallToMedium + 0.001)) {
		@content;
	}
}

$breakpoint-mediumToLarge: 53em;
@mixin breakpoint-medium-until () {
	@media (max-width: $breakpoint-mediumToLarge) {
		@content;
	}
}
@mixin breakpoint-large-from () {
	@media (min-width: ($breakpoint-mediumToLarge + 0.001)) {
		@content;
	}
}

$breakpoint-largeToHuge: 60em;
@mixin breakpoint-large-until () {
	@media (max-width: $breakpoint-largeToHuge) {
		@content;
	}
}
@mixin breakpoint-huge-from () {
	@media (min-width: ($breakpoint-largeToHuge + 0.001)) {
		@content;
	}
}
