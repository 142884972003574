.introduction {

	// If shape-outside is affecting text element so much that a word can not fit in one line,
	// Firefox correctly wrap it. Chrome splits the word to letters instead so we have to
	// make an offset manually.
	@media (max-width: 25em) {
		padding-top: multiple-of-lineHeight-unit(6);
	}

	&__activities {
		margin-bottom: multiple-of-lineHeight-unit(1);
	}

	&__activity {
		&:not(:last-child) {
			margin-bottom: multiple-of-lineHeight-unit(0.25);
		}
	}
}
