.content {
	margin-bottom: multiple-of-lineHeight-unit(3);

	&::before {
		content: "";
		background-image: url("../images/background.jpg");
		background-position: right top;
		background-repeat: no-repeat;
		background-size: auto 100%;
		display: block;
		width: 15rem;
		height: 15rem;
		float: right;
		margin-bottom: multiple-of-lineHeight-unit(1);

		@include breakpoint-medium-from () {
			width: 20rem;
			height: 20rem;
		}
		@include breakpoint-large-from () {
			width: 30rem;
			height: 30rem;
		}
		@include breakpoint-huge-from () {
			width: 40rem;
			height: 40rem;
			margin-right: -#{multiple-of-lineHeight-unit(7)};
		}

		@supports (shape-outside: polygon(0 0)) {
			shape-outside: polygon(1em 0, 0.58em 5.54em, 2.22em 6.05em, 7.09em 5.92em, 7.19em 13.63em, 13.69em 13.75em, 14em 0);
			shape-margin: multiple-of-lineHeight-unit(1);
			margin-bottom: 0; // `revert` would fit better but it has less support than `shape-outside` :-)

			@include breakpoint-medium-from () {
				shape-outside: polygon(1em 0, 0.64em 6.40em, 3.14em 8em, 9.61em 7.58em, 9.71em 18.61em, 18.5em 18.5em, 18.5em 0);
			}
			@include breakpoint-large-from () {
				shape-outside: polygon(2.09em 0, 1.04em 9.55em, 4.52em 12.02em, 14.26em 11.31em, 14.3em 27.44em, 27.79em 27.91em, 28.08em 0);
			}
			@include breakpoint-huge-from () {
				shape-outside: polygon(2.09em 0, 1.5em 12.76em, 6.30em 15.92em, 19.02em 15.15em, 19.41em 36.85em, 37.25em 36.97em, 37.31em 0);
			}
		}
	}

	&__section {
		margin-bottom: multiple-of-lineHeight-unit(2);

		@include breakpoint-large-from () {
			margin-bottom: multiple-of-lineHeight-unit(3);
		}
	}

	h1 {
		text-transform: uppercase;
	}
}
