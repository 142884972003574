.aside {
	&,
	&__cooperation,
	&__supporters {
		margin-bottom: multiple-of-lineHeight-unit(2);

		@include breakpoint-large-from () {
			margin-bottom: multiple-of-lineHeight-unit(3);
		}
	}
}
