.orderedList {
	list-style-type: none;
	counter-reset: order;

	&__item {
		padding-left: 0.5rem;
		margin-bottom: multiple-of-lineHeight-unit(0.25);
		counter-increment: order;

		&::before {
			content: counter(order);
			float: left;
			margin-left: -2rem;
			background-color: $colors-heading;
			color: $colors-background;
			clip-path: circle(50%);
			width: multiple-of-lineHeight-unit(1);
			height: multiple-of-lineHeight-unit(1);
			padding-left: 0.5rem;
			font-size: 0.8em;
			padding-top: 0.1em;
		}
	}
}
