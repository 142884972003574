.supporters {
	@include breakpoint-large-from () {
		max-width: 40rem;
		margin-left: auto;
		margin-right: auto;
	}

	&__heading {
		text-align: center;
	}

	&__supporter {
		margin-bottom: multiple-of-lineHeight-unit(0.25);
	}
}
