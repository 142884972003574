.footer {
	@include breakpoint-large-from () {
		display: grid;
		grid-template-columns: 1fr 1fr 2fr;
		grid-column-gap: multiple-of-lineHeight-unit(1);
	}

	&__contactAndPaymentInformation {
		@include breakpoint-medium-from () {
			@include breakpoint-medium-until () {
				display: flex;
			}
		}
	}
	&__contactAndPaymentInformation &__section:first-child {
		@include breakpoint-medium-from () {
			@include breakpoint-medium-until () {
				margin-right: multiple-of-lineHeight-unit(4);
			}
		}
	}

	&__section {
		margin-bottom: multiple-of-lineHeight-unit(1);
		font-size: 0.8em; // make footer's text smaller

		h2 {
			font-size: 1.1rem; // fallback – must be synced manually with h2 font-size rule in defaults
			font-size: revert; // this is better but not supported in all browsers
		}
	}

	&__contactAddress {
		font-style: normal;
	}

	&__commissioner {
		font-size: 0.85em;
		line-height: 1.3;
	}
}
