.partners {
	&__heading {
		margin-bottom: multiple-of-lineHeight-unit(1);
		text-align: center;
	}

	&__list {
		list-style-type: none;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: multiple-of-lineHeight-unit(1);
		justify-items: center;
		align-items: center;
	}

	&__link {
		display: flex;
		justify-content: center;
	}

	&__logo {
		width: 100%;
		align-self: center; // prevents image deformation in Safari, @see https://stackoverflow.com/a/37610279/3668474
	}
	&__company--microsoft &__logo {
		max-width: 11em;
	}
	&__company--csystem &__logo {
		max-width: 13em;
	}
	&__company--soitron &__logo {
		max-width: 12em;
	}
}
