.cooperation {
	&__heading {
		margin-bottom: multiple-of-lineHeight-unit(1);
		text-align: center;
	}

	&__list {
		list-style-type: none;
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(7rem, 1fr));
		grid-gap: multiple-of-lineHeight-unit(1);
		justify-items: center;
		align-items: center;
		margin-block-end: multiple-of-lineHeight-unit(1);

		@include breakpoint-medium-from () {
			grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
		}
	}

	p {
		max-width: 37rem;
		margin-inline: auto;
		text-align: center;

		@include breakpoint-small-until() {
			font-size: 0.8em;
		}

		&:not(:last-child) {
			margin-bottom: multiple-of-lineHeight-unit(0.5);
		}
	}

	&__link {
		display: flex;
		justify-content: center;
	}

	&__logo {
		width: 100%;
		align-self: center; // prevents image deformation in Safari, @see https://stackoverflow.com/a/37610279/3668474
	}
	&__institution--fnusa-icrc &__logo {
		max-width: 7em;
	}
	&__institution--fn-brno &__logo {
		max-width: 10em;
	}
	&__institution--munimed &__logo {
		max-width: 7em;
	}
	&__institution--munimed-iba &__logo {
		max-width: 9em;
	}
	&__institution--cps-jep &__logo {
		max-width: 50%;

		@include breakpoint-medium-from () {
			max-width: 5.1em;
		}
	}
	&__institution--cns-jep &__logo {
		max-width: 9em;
	}
	&__institution--uzis-cr &__logo {
		max-width: 7em;
	}
	&__institution--fnusa &__logo {
		max-width: 9em;
	}
	&__institution--ceitec-vutbr &__logo {
		max-width: 12em;
	}
	&__institution--mzcr &__logo {
		max-width: 10em;
	}
	&__institution--jmk &__logo {
		max-width: 11em;
	}
}
