.header {
	padding-top: multiple-of-lineHeight-unit(1);
	padding-bottom: multiple-of-lineHeight-unit(1);

	@include breakpoint-medium-from () {
		padding-bottom: multiple-of-lineHeight-unit(2);
	}

	&__logo {
		max-width: 12em;
	}
}
