.dashedList {
	list-style-type: none;

	/*
	 * `li::marker` is not yet really supported across browsers so we have to hack it.
	 * Negative margin on floated pseudoelement ensures that text is not floated around it
	 * but the pseudoelement is sticked outside of the text.
	 *
	 * Compare this:
	 * – Lorem ipsum dolor
	 * sit amet lorem ipsum.
	 *
	 * to this:
	 * – Lorem ipsum dolor
	 *   sit amet lorem ipsum.
	 */
	&__item {
		padding-left: 1.05em;

		&::before {
			content: "–";
			float: left;
			margin-left: -1.05em;
		}
	}
}
